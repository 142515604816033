var columns = [{
  title: '类目级别',
  dataIndex: 'stage',
  key: 'stage',
  // width: '4%',
  scopedSlots: {
    customRender: 'stage'
  }
}, {
  title: '类目ID',
  dataIndex: 'id',
  key: 'id',
  // width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '类目名称',
  dataIndex: 'name',
  key: 'name',
  // width: '7%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '类目图片',
  key: 'imgurl',
  dataIndex: 'imgurl',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '商品',
  key: 'productnum',
  dataIndex: 'productnum',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'productnum'
  }
}, {
  title: '创建时间',
  key: 'createtime',
  dataIndex: 'createtime',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };