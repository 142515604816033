var searchKeys = [{
  key: "parentid",
  label: "类目",
  placeholder: "请选择类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "id",
  label: "",
  placeholder: "二级类目",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };